import React from "react";

// Custom styles
import "./servicesCard.styles.scss";

function ServicesCard(props) {
  const { src, name, title, text } = props;
  return (
    <div className="service__card">
      <div className="service__card__heading">
        <h4 className="service__card_title">{title}</h4>
        <img
          src={`${process.env.REACT_APP_HOME_API_URL}${src}`}
          alt="AFIX SERVICIOS FINANCIEROS"
          className="service__card_img"
        />
      </div>
      <div className="service__card__body">
        <p className="service__card_text">{text}</p>
      </div>
    </div>
  );
}

export default ServicesCard;

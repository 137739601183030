import { useState, useEffect } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom components
import ArticleCard from "../components/ArticleCard/ArticleCard";

// API
import { getCategories, getPosts, getBlogContent } from "../api/blog";

// Custom styles
import "../scss/pages/blog.styles.scss";

function Blog() {
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getCategories();
        if (response.status === 304) {
          setCategoriesData(result.docs);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setCategoriesData(result.docs);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getPosts();
        if (response.status === 304) {
          setArticlesData(result.docs);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setArticlesData(result.docs);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPostsData();
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getBlogContent();
        if (response.status === 304) {
          setBlogData(result.docs[0]);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setBlogData(result.docs[0]);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogData();
  }, []);

  if (
    categoriesData.length === 0 &&
    articlesData.length === 0 &&
    Object.keys(blogData).length === 0 &&
    blogData.constructor === Object
  ) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }
  return (
    <>
      <section className="hero__secondary__wrapper">
        <div className="container">
          <div
            className="hero__secondary__container"
            style={{
              backgroundImage: `linear-gradient(
                90deg,
                rgba(56, 163, 2, 0.58) 31%,
                rgba(210, 210, 210, 0) 70%
              ),url(${process.env.REACT_APP_HOME_API_URL}${blogData?.BlogMedia?.url})`,
            }}
          >
            <h2>{blogData.blogTitle}</h2>
            <p>{blogData.blogParagraph}</p>
          </div>
        </div>
      </section>
      <section className="articles__container container">
        <div className="articles__container__filters">
          {/* {children[0]} */}
          <div className="filters">
            <div
              className={`accordion custom__accordion`}
              id="filtersAccordion"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button filters__heading`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <p>Categorías</p>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#filtersAccordion"
                >
                  <div className={`accordion-body custom__accordion__body`}>
                    <ul className="list-group">
                      {categoriesData.map((category, id) => (
                        <li
                          key={id}
                          className="list-group-item custom-checkbox"
                        >
                          <input
                            type="checkbox"
                            id={`checkbox${id}`}
                            className="check-input"
                          />
                          <label htmlFor={`checkbox${id}`} className="ms-3">
                            {category.name}
                          </label>
                        </li>
                      ))}
                    </ul>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {React.cloneElement(children[0])} */}
        </div>
        <div className="articles__container__cards">
          {articlesData.map((data, id) => (
            <ArticleCard key={id} data={data} />
          ))}
          {/* {children[1]} */}
          {/* <ArticleCards /> */}
        </div>
      </section>
    </>
  );
}

export default Blog;

import { useState, useEffect } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/une.styles.scss";

// Assets
import locationIcon from "../assets/images/location-icon--green.svg";
import profileIcon from "../assets/images/profile-icon--green.svg";
import phoneIcon from "../assets/images/phone-icon--green.svg";
import emailIcon from "../assets/images/email-icon--green.svg";
import uneHero from "../assets/images/une-hero-bg.jpg";

function UNE() {
  return (
    <div className="special_unit__wrapper">
      <section className="special_unit__content">
        <div className="special_unit__body">
          <h4>Unidad Especializada de Atención a Clientes</h4>
          <div className="special_unit__body_card">
            <p>
              Para dudas, aclaraciones y reclamaciones deberás dirigirte a la
              Unidad Especializada de atención a usuarios, localizada en:
            </p>
            <a
              href="https://maps.app.goo.gl/ernUY12YkWS7FZ4n6"
              target="_blank"
              rel="noreferrer"
              className="my-2 d-block"
            >
              <img src={locationIcon} alt="" />
              Av. Américas 1930. Piso 10 UP-2 Col. Country Club. C.P. 44610.
              Guadalajara, Jalisco. MX.
            </a>
            <p className="mt-2">
              Además, ponemos a su disposición nuestra página de internet:{" "}
              <a href="www.afix.com.mx">www.afix.com.mx</a>. En el caso de
              detectar impostores/posible robo de identidad, por favor
              comunicarte con el Titular de la UNE:
            </p>
            <div className="special_unit__body_card_contacts">
              <div className="special_unit__body_card_contacts_card">
                <h6>Titular:</h6>
                <div>
                  <img src={profileIcon} alt="" />
                  <p>Iliana Álvarez</p>
                </div>
                <div>
                  <img src={phoneIcon} alt="" />
                  <a href="tel:+523336301888">(33) 36 30 18 88</a>
                </div>
                <div>
                  <img src={emailIcon} alt="" />
                  <a href="mailto:ialvarez@afix.com.mx">ialvarez@afix.com.mx</a>
                </div>
              </div>
              {/* <div className="special_unit__body_card_contacts_card">
                <h6>Otros contactos:</h6>
                <div>
                  <img src={profileIcon} alt="" />
                  <p>Iliana Álvarez</p>
                </div>
                <div>
                  <img src={phoneIcon} alt="" />
                  <a href="tel:+523336301888">(33) 36 30 18 88</a>
                </div>
                <div>
                  <img src={emailIcon} alt="" />
                  <a href="mailto:ialvarez@afix.com.mx">ialvarez@afix.com.mx</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="special_unit__media">
          <img src={uneHero} alt="" />
        </div>
      </section>
    </div>
  );
}

export default UNE;

import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/article.styles.scss";

// API
import { getArticleContent } from "../api/blog";

// Assets
import phoneWhite from "../assets/images/phone--white.svg";

function Article() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [articleData, setArticleData] = useState({});
  // console.log(id);
  // const data = await getData(params.article);
  // console.log(data.layout[1]);
  const date = new Date(articleData?.publishDate);
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getArticleContent(id);
        if (response.status === 304) {
          setArticleData(result);
        } else if (!response.ok) {
          console.error("Error fetching Article :", response.ok);
        } else {
          setArticleData(result);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (
    Object.keys(articleData).length === 0 &&
    articleData.constructor === Object
  ) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }

  return (
    <div className="article__wrapper">
      <section className="article__hero">
        <div className="container article__hero__container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
              <h1>{articleData.title}</h1>
              <p>{articleData.description}</p>
            </div>
            <div className="col-12 col-lg-6 article__hero__media">
              <img
                src={`${process.env.REACT_APP_HOME_API_URL}${
                  articleData.ArticleMedia != null
                    ? articleData.ArticleMedia.url
                    : ""
                }`}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="article__body">
        <div className="container article__body__header">
          <p>{formattedDate}</p>
          <div className="article__body__header__line"></div>
        </div>
        <div className="container article__body__container">
          <div className="article__body__container__banners">
            <div className="banner banner__quote">
              <h6 className="mb-2">
                Solicita cualquiera de nuestros créditos hoy mismo
              </h6>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Debitis, corrupti.
              </p> */}
              <a href="tel:+523336301888">
                <img src={phoneWhite} alt="" />
                <span>Habla con un asesor: (33)-36-30-18-88</span>
              </a>
            </div>
          </div>
          <div className="article__body__container__content">
            <h6>
              {articleData.category.name != null
                ? articleData.category.name
                : ""}
            </h6>
            {/* <p>{articleData.layout[0].ArticleBody[0].children[0].text}</p> */}
            {articleData.layout.length >= 1 ? (
              <div>
                {articleData.layout[0].ArticleBody.length >= 1 &&
                  articleData.layout[0].ArticleBody.map((item, key) => (
                    <p className="mb-2">{item.children[0].text}</p>
                  ))}
              </div>
            ) : null}
            {/* {console.log(articleData.ArticleBanner.url)} */}
            {articleData.layout.length >= 1 ? (
              <div>
                {articleData.layout[1].ArticleBody.map((item, key) => {
                  // console.log(item.children);
                  return item.children.some((i) => i.type === "link") ? (
                    // console.log(
                    //   "this has type link",
                    //   item.children[1].children[0].text
                    // )
                    <a
                      href={`${item.children[1].url}`}
                      className="d-block mt-2"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {item.children[1].children[0].text}
                    </a>
                  ) : (
                    // <p key={key}>{item.children[0].text}</p>
                    <p className="mb-2" key={key}>
                      {item.children[0].text}
                    </p>
                  );
                })}
                {articleData.ArticleBanner !== null ? (
                  <div
                    className="article__main__media"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_HOME_API_URL}${articleData.ArticleBanner?.url})`,
                      display: "block",
                    }}
                  ></div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Article;

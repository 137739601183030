import React from "react";
import { Link } from "react-router-dom";

// Custom styles
import "./articleCard.styles.scss";

// Assets
import arrowFigure from "../../assets/images/arrow.svg";

function ArticleCard({ data }) {
  const { title, publishDate, category, description, id } = data;
  const date = new Date(publishDate);
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
  // console.log(category);
  return (
    <Link to={`/blog/${id}`} className="card__link">
      {/* <Link href={`${process.env.API_URL}/posts/${id}`} className={card__link}> */}
      <div className="card">
        <div className="card__published__at">
          <p>{formattedDate}</p>
        </div>
        <div className="card__heading">
          <h6>AFIX SERVICIOS FINANCIEROS</h6>
          <h5>{category.name}</h5>
        </div>
        <div className="card__body">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className="card__footer">
          <img src={arrowFigure} alt="" />
        </div>
      </div>
    </Link>
  );
}

export default ArticleCard;

import React from "react";

// Custom components
import MarketsBenefits from "../MarketsBenefits/MarketsBenefits";

// Custom styles
import "./markets.styles.scss";

function Markets(props) {
  const { id, aLabelledby, name, beneficios, marketShortDescription, text } =
    props;
  // console.log(text);
  return (
    <div
      id={id}
      role="tabpanel"
      aria-labelledby={aLabelledby}
      className="tab-pane fade tab-market markets"
    >
      <div className="container-fluid">
        <h2 className="markets_name">{name}</h2>
        <p className="markets_text">{marketShortDescription}</p>
        {/* <h6 className="markets_about">Acerca del servicio</h6> */}
        {/* <p className={markets_aboutText}>{text}</p> */}
        {text.length > 1 ? (
          text.map((description, key) => (
            <p className="markets_text" key={{ key }}>
              {description.children[0].text}
            </p>
          ))
        ) : (
          <p className="markets_text">{text[0].children[0].text}</p>
        )}
        <h5 className="markets_benefistText">Beneficios</h5>
        <div className="benefitsClassName">
          {beneficios.map((benefit, key) => (
            <MarketsBenefits
              key={key}
              icono={`https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run${benefit.benefitMedia.url}`}
              titulo={benefit.benefitTitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Markets;

import { useState, useEffect } from "react";

// API
import { getTeam } from "../api/about";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/aboutUs.styles.scss";

function AboutUs() {
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getTeam();
        if (response.status === 304) {
          setTeamData(result.docs);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setTeamData(result.docs);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading && teamData.length === 0) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }
  return (
    <div className="about_us__wrapper">
      <section className="about_us__hero">
        <div className="container-fluid">
          <h4>Nuestro propósito</h4>
          <p>
            Impulsamos el crecimiento profesional y económico de empresas en
            diferentes etapas, mediante soluciones financieras, buscando{" "}
            <span>generar un impacto positivo</span> en nuestro equipo, el medio
            ambiente y la comunidad.
          </p>
        </div>
      </section>
      {/* {console.log(teamData)} */}
      <section className="about_us__team">
        <div className="about_us__team__hero">
          <div className="container-fluid">
            <h4>Nuestro equipo:</h4>
            <p>
              En AFIX atribuimos el éxito de nuestra organización a nuestro
              equipo de Colaboradores, quienes hacen que las cosas sucedan día a
              día, te presentamos a los líderes de nuestras áreas:{" "}
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="about_us__team__cards">
            {teamData?.map((card, key) => (
              <div
                key={key}
                className="about_us__team__card"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_HOME_API_URL}${card.TeamMedia.url})`,
                }}
              >
                <h6>{card.position}</h6>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;

// Custom styles
import "./counter.styles.scss";

import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";

// const { circle } = Styles;
function Counter({ finalValue, children }) {
  //   return (
  //     <div key={key} className={`${circle} circle${key}`}>
  //                   <h5>{individualStatistic}</h5>
  //                   {/* <p>{stat.caption}</p> */}
  //                 </div>
  //   )
  const [counter, setCounter] = useState(0);
  const duration = 2000; // 1 second
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation only once
  });

  useEffect(() => {
    if (inView) {
      let animationFrameId;
      const startTimestamp = performance.now();

      function animateCounter(timestamp) {
        const elapsed = timestamp - startTimestamp;
        const progress = Math.min(1, elapsed / duration);
        const newValue = Math.round(progress * finalValue);

        setCounter(newValue);

        if (progress < 1) {
          animationFrameId = requestAnimationFrame(animateCounter);
        }
      }

      animationFrameId = requestAnimationFrame(animateCounter);

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [inView, finalValue]);

  return (
    // <div>
    //   <div ref={ref} id="counter">
    //     {inView ? counter : 0}
    //   </div>
    //   <button onClick={() => setCounter(0)}>Reset</button>
    // </div>
    <h5 ref={ref} id="counter">
      {inView ? counter : 0}
      {children}
    </h5>
  );
}

export default Counter;

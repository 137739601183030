import React from "react";

// Custom styles
import "../scss/pages/condusef.styles.scss";

function Condusef() {
  return (
    <div className="condusef__wrapper">
      <section className="condusef__hero">
        <div className="container-fluid">
          <h3>
            Comisión Nacional para la Protección y Defensa de los Usuarios de
            Servicios Financieros (CONDUSEF)
          </h3>
        </div>
      </section>
      <section className="condusef__content">
        <div className="container py-4">
          <a
            href="https://webapps.condusef.gob.mx/SIPRES/jsp/home_publico.jsp?idins=2900"
            target="_blank"
            className="condusef__content_cta"
          >
            Valídanos aquí
          </a>
          <p>
            En caso de dudas, quejas, reclamaciones o consultar información
            sobre las comisiones para fines informaTvos y de comparación, podrá
            acudir a la CONDUSEF, con domicilio en Insurgentes Sur N° 762,
            Colonia del Valle, Delegación Benito Juárez, Código Postal 03100,
            México, Distrito Federal, correo electrónico
            opinion@condusef.gob.mx, teléfono 01800 999 8080 y 5340 0999, o
            consultar la página electrónica en internet www.condusef.gob.mx
          </p>
          <p>
            ¿Tienes dudas sobre fraudes financieros? Accede al portal de{" "}
            <a
              href="https://phpapps.condusef.gob.mx/fraudes_financieros/monitor.php?r=1073%207&id=5"
              target="_blank"
            >
              CONDUSEF.
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Condusef;

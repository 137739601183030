import React from "react";

// Custom styles
import "./marketsBenefits.styles.scss";

function MarketsBenefits(props) {
  const { icono, titulo } = props;
  return (
    <>
      <div className="benefits__container">
        <div className="benefits__container__background">
          <img
            src={icono}
            className="benefits__container__background_img"
            alt=""
          />
        </div>
        <h4 className="benefits__container_text">{titulo}</h4>
      </div>
    </>
  );
}

export default MarketsBenefits;
